export function Contact(){
    return (
        <section id="contact" className="contact">
            <div className="container">

                <div className="section-title">
                    <h2>Свазаться с нами</h2>
                </div>

                <div className="row mt-2">
                    <div className="col-md-6 mt-4 d-flex align-items-stretch">
                        <div className="info-box">
                            <i className="bx bx-envelope"></i>
                            <h3>Email:</h3>
                            <p><a href="mailto:info@altworld.online">info@altworld.online</a></p>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4 d-flex align-items-stretch">
                        <div className="info-box">
                            <i className="bx bx-phone-call"></i>
                            <h3>Discord:</h3>
                            <p><a target="_blank" href="https://discord.gg/HmjzsYTx3x">https://discord.gg/HmjzsYTx3x</a></p>
                        </div>
                    </div>
                </div>

                <form className="php-email-form mt-4">
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <input type="text" name="name" className="form-control" id="name" placeholder="Имя" required/>
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                            <input type="email" className="form-control" name="email" id="email" placeholder="Email" required/>
                        </div>
                    </div>
                    <div className="form-group my-3">
                        <textarea className="form-control" name="message" rows="5" placeholder="Сообщение" required></textarea>
                    </div>
                    <div className="text-center">
                        <button type="submit">Отправить</button>
                    </div>
                </form>

            </div>
        </section>
    )
}
