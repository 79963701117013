import {NavLink} from "react-router-dom";
import {navHandler} from "../navHandler";



export function Menu(){
    return (
            <nav id="navbar" className="navbar">
                <ul>
                    <li><NavLink onClick={()=>{navHandler('main')}} className="nav-link" exact="true" to="/">Главная</NavLink></li>
                    <li><NavLink onClick={()=>{navHandler('game_start')}} className="nav-link" to="/game_start">Как начать играть?</NavLink></li>
                    <li><NavLink onClick={()=>{navHandler('donate')}} className="nav-link" to="/donate">Донат</NavLink></li>
                    <li><NavLink onClick={()=>{navHandler('wiki')}} className="nav-link" to="/wiki">Wiki</NavLink></li>
                    <li><NavLink onClick={()=>{navHandler('contact')}} className="nav-link" to="/contact">Связаться с нами</NavLink></li>
                    <li><NavLink onClick={()=>{navHandler('profile')}} className="nav-link" to="/profile">Личный кабинет</NavLink></li>
                </ul>
                <i className="bi bi-list mobile-nav-toggle"/>
            </nav>
        )

}
