export function navHandler(page){
    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }
    const scrollto = (el) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    let header = select('#header');
    let navbar = select('#navbar');
    document.querySelectorAll('.nav-link').forEach(item=>item.classList.remove('active'));
    select('.mobile-nav-toggle').onclick = function(e) {
        navbar.classList.toggle('navbar-mobile')
        this.classList.toggle('bi-list')
        this.classList.toggle('bi-x')
    }

    if (navbar.classList.contains('navbar-mobile')) {
        navbar.classList.remove('navbar-mobile')
        let navbarToggle = select('.mobile-nav-toggle')
        navbarToggle.classList.add('bi-list')
        navbarToggle.classList.remove('bi-x')
    }
    if(page==='') page = 'main';
    setTimeout(()=>{
        if(page === 'main'){
            let sections = select('section', true)
            header.classList.remove('header-top');
            sections.forEach((item) => {
                item.classList.remove('section-show')
            })
            return;
        }
        let sectionId = document.getElementById(page);

        setTimeout(function() {
            if(sectionId===null) return;
            sectionId.classList.add('section-show')
        }, 200);
        header.classList.add('header-top');
        scrollto(sectionId);
    }, 100);
}
