import Modal from 'react-modal';
import React, {useState} from 'react';
const customStyles = {
    overlay:{
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        zIndex: 2,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: null,
        border: null,
        width: '100%'
    },
};

function SponsorDesc(){
    return (
        <div>
            <h3>Описание</h3>
            <p> Просто произовольная сумма пожертвования на хост проекта, чтобы сервер работал ещё N дней...<br/>
                Средства уходят на оплату сервера...<br/>
                Вы получите префикс [спонсор] на 30 дней<br/>
                Спасибо, за поддержку сервера!<br/>
            </p>
        </div>
    )
}
function MajorDesc(){
    return (<div>
        <p> <strong>400р.</strong> пожертвования на хост проекта, чтобы сервер работал ещё пару дней<br/>
            Средства уходят на оплату сервера...<br/>
            Вы получите префикс [Мажор] на 30 дней<br/>
            За каждые 10 минут онлайна на сервере вы получаете 100ед. игровой валюты<br/>
        </p>
    </div>)
}
function PlatinumDesc(){
    return (<div>
        <p> <strong>6000р.</strong> пожертвования на хост проекта, чтобы сервер работал ещё месяц<br/>
            Средства уходят на оплату сервера...<br/><br/>
            <strong>Вы получите:</strong>
            <ul>
                <li>Префикс [Platinum] на 30 дней</li>
                <li>20 приватов</li>
                <li>30 слотов на аукционе</li>
                <li>120монет/10мин</li>
                <li>Виртуальный эндер сундук</li>
                <li>Виртуальный верстак</li>
                <li>Потушить себя</li>
                <li>Бесконечный /dback</li>
                <li>20 домов</li>
                <li>Можно зайти на переполненный сервер</li>
            </ul>
        </p>
    </div>)
}
function EliteDesc(){
    return (<div>
        <p> <strong>1200р.</strong> пожертвования на хост проекта, чтобы сервер работал ещё неделю<br/>
            Средства уходят на оплату сервера...<br/><br/>
            <strong>Вы получите:</strong>
            <ul>
                <li>Префикс [Elite] на 30 дней</li>
                <li>6 приватов</li>
                <li>10 слотов на аукционе</li>
                <li>40 монет/10мин</li>
                <li>Бесконечный /dback</li>
                <li>Виртуальный эндер сундук</li>
                <li>Виртуальный верстак</li>
                <li>6 домов</li>
                <li>Можно зайти на переполненный сервер</li>
            </ul>
        </p>
    </div>)
}
function PremiumDesc(){
    return (<div>
        <p> <strong>600р</strong> пожертвования на хост проекта, чтобы сервер работал ещё несколько дней...<br/>
            Средства уходят на оплату сервера...<br/><br/>
            <strong>Вы получите:</strong>
            <ul>
                <li>Префикс [Premium] на 30 дней</li>
                <li>4 привата</li>
                <li>6 слотов на аукционе</li>
                <li>20 монет/10мин</li>
                <li>Виртуальный эндер сундук</li>
                <li>Виртуальный верстак</li>
                <li>4 дома</li>
                <li>Можно зайти на переполненный сервер</li>
            </ul>
        </p>
    </div>)
}
function LuxuryDesc(){
    return (<div>
        <p>
        <strong>3000р</strong> пожертвования на хост проекта, чтобы сервер работал ещё пол месяца.<br/>
        Средства уходят на оплату сервера...<br/><br/>
        <strong>Вы получите:</strong>
            <ul>
                <li>Префикс [Luxury] на 30 дней</li>
                <li>10 приватов</li>
                <li>15 слотов на аукционе</li>
                <li>60монет/10мин</li>
                <li>Виртуальный эндер сундук</li>
                <li>Виртуальный верстак</li>
                <li>Потушить себя</li>
                <li>Бесконечный /dback</li>
                <li>10 домов</li>
                <li>Можно зайти на переполненный сервер</li>
            </ul>
        </p>
    </div>)
}

function ModalContent(props){
    return (
        <div className="col-12">
            <div className="modal-dialog modal-xl" >
                <div className="modal-content" style={{backgroundColor: 'rgba(0, 0, 0, 0.9)'}}>
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                        <a className="bx bx-x text-white" style={{fontSize: '1.7rem', cursor: "pointer"}} data-bs-dismiss="modal"
                           aria-label="Закрыть" onClick={props.closeModal}/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 text-center">
                                <img src={props.img} alt="" width="70%"/>
                            </div>
                            <div className="col-sm-8">
                                <props.componentDesc/>
                                <hr/>
                                Возможен приём валют: Рубли, Гривны, Евро
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        Для покупки свяжитесь с администратором сервера в Дискорде <a href="https://discord.com/users/677141533547560963/" target="_blank">Vladlen</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Donate(){
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState()
    function openModal(title, img, componentDesc) {
        setIsOpen(true);
        setModalContent(<ModalContent title={title} img={img} closeModal={closeModal} componentDesc={componentDesc}/>)
    }
    function afterOpenModal() {
        //afterOpenModal
    }
    function closeModal() {
        setIsOpen(false);
    }
    return (
        <section id="donate" className="portfolio">
            <div className="container">
                <div className="section-title">
                    <h2>Пожертвования</h2>
                    <p>Спасибо за поддержку проекта!</p>
                </div>

                <div className="row portfolio-container">
                    <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                            <img src="assets/img/donate/premium.png" className="img-fluid" alt=""/>
                            <div className="portfolio-info">
                                <h4>Премиум</h4>
                                <div className="portfolio-links">
                                    <a onClick={()=>{openModal("Премиум", "assets/img/donate/premium.png", PremiumDesc)}} data-gallery="portfolioGallery"
                                       className="portfolio-lightbox" style={{fontSize: '3rem', cursor: "pointer"}}><i
                                        className="bx bx-expand"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                            <img src="assets/img/donate/elite.png" className="img-fluid" alt=""/>
                            <div className="portfolio-info">
                                <h4>Элита</h4>
                                <div className="portfolio-links">
                                    <a onClick={()=>{openModal("Elite", "assets/img/donate/elite.png", EliteDesc)}} data-gallery="portfolioGallery"
                                       className="portfolio-lightbox" style={{fontSize: '3rem', cursor: "pointer"}}><i
                                        className="bx bx-expand"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                            <img src="assets/img/donate/luxury.png" className="img-fluid" alt=""/>
                            <div className="portfolio-info">
                                <h4>Люкс</h4>
                                <div className="portfolio-links">
                                    <a onClick={()=>{openModal("Luxury", "assets/img/donate/luxury.png", LuxuryDesc)}} data-gallery="portfolioGallery"
                                       className="portfolio-lightbox" style={{fontSize: '3rem', cursor: "pointer"}}><i
                                        className="bx bx-expand"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                            <img src="assets/img/donate/platinum.png" className="img-fluid" alt=""/>
                            <div className="portfolio-info">
                                <h4>Платинум</h4>
                                <div className="portfolio-links">
                                    <a onClick={()=>{openModal("Platinum", "assets/img/donate/platinum.png", PlatinumDesc)}} data-gallery="portfolioGallery"
                                       className="portfolio-lightbox" style={{fontSize: '3rem', cursor: "pointer"}}><i
                                        className="bx bx-expand"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                            <img src="assets/img/donate/major.png" className="img-fluid" alt=""/>
                            <div className="portfolio-info">
                                <h4>Мажор</h4>
                                <div className="portfolio-links">
                                    <a onClick={()=>{openModal("Мажор", "assets/img/donate/major.png", MajorDesc)}} data-gallery="portfolioGallery"
                                       className="portfolio-lightbox" style={{fontSize: '3rem', cursor: "pointer"}}><i
                                        className="bx bx-expand"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                            <img src="assets/img/donate/sponsor.png" className="img-fluid" alt=""/>
                            <div className="portfolio-info">
                                <h3>Спонсор</h3>
                                <div className="portfolio-links">
                                    <a onClick={()=>{openModal("Спонсор", "assets/img/donate/sponsor.png", SponsorDesc)}} data-gallery="portfolioGallery"
                                       className="portfolio-lightbox"
                                       style={{fontSize: '3rem', cursor: "pointer"}}><i
                                        className="bx bx-expand"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
            >
                {modalContent}
            </Modal>
        </section>
    )
}
