import logo from './logo.svg';
import './App.css';
import {Header} from "./components/Header";
import {GameStart} from "./components/GameStart";
import {Profile} from "./components/Profile";
import {Contact} from "./components/Contact";
import {
    BrowserRouter,
    Routes,
    Route,NavLink
} from "react-router-dom";
import {useEffect} from "react";
import {navHandler} from "./navHandler";
import {AddBlock} from "./components/AddBlock";
import {Donate} from "./components/Donate";
import {Wiki} from "./components/Wiki";
import {Block} from "./components/Block";


function App() {
    useEffect(() => {
        navHandler(window.location.pathname.split('/')[1]);
    });
  return (
          <BrowserRouter>
              <Header/>
              <Routes>
                  <Route path="/game_start" element={<GameStart/>}/>
                  <Route path="/profile" element={<Profile/>}/>
                  <Route path="/contact" element={<Contact/>}/>
                  <Route path="/addBlock" element={<AddBlock/>}/>
                  <Route path="/donate" element={<Donate/>}/>
                  <Route path="/wiki" element={<Wiki/>}/>
                  <Route path="/block/:id" element={<Block/>}/>
              </Routes>
              <div className="credits">
                  Hosting by <a href="https://beget.com/p54123">BeGet</a>
              </div>

          </BrowserRouter>
  );
}

export default App;
