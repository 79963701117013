import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {navHandler} from "../navHandler";

function Block(props){
    return (
        <div className="col-lg-1 col-md-2 text-center mb-3">
            <Link onClick={()=>{navHandler('block')}} to={`/block/${props.id}`}>
                <div className="icon-box p-2 justify-content-center">
                    <img src={props.img} width="100%" alt=""/>
                </div>
            </Link>
        </div>
    )
}
export function Wiki(){
    const [blocks, setBlocks] = useState([]);
    useEffect(()=>{
        fetch('/getBlocks')
            .then(response=>response.json())
            .then(result=>{
               console.log(result);
               setBlocks(result.map(item=><Block key={item.id} id={item.id} img={item.image}/>));
            });
    }, [])
    return (
            <section id="wiki">
                <div className="container interests">
                    <div className="section-title">
                        <h2>AltWorld - wiki</h2>
                    </div>
                    <div className="row">
                        {blocks}
                    </div>
                </div>
            </section>
    )
}
