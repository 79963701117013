export class HelpMethod{
    static getUserData(setState){
        fetch('/user')
            .then(response=>response.json())
            .then(result=>{
                let regDate = new Date(result.regdate);
                let month = (regDate.getMonth()+1)<10?"0"+(regDate.getMonth()+1):regDate.getMonth()+1;
                result.renderDate = regDate.getDate() + "." + (month) + "." + regDate.getFullYear();
               setState(result);
            });
    }
}
