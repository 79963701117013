import {NavLink} from "react-router-dom";
import {Menu} from "./Menu";
import {useEffect, useState} from "react";

export function Header(){
    const [onlinePlayers, setOnlinePlayers] = useState({"players":0,"maxPlayers":0});
    useEffect(()=>{
        fetch('/onlineUsers').then(response=>response.json()).then(result=>setOnlinePlayers(result));
    }, [onlinePlayers.players, onlinePlayers.maxPlayers])

    return (
        <header id="header">
            <div className="container">

                <h1><a href="/">AltWorld</a></h1>
                <h2>IP-сервера: altworld.online</h2>

                <Menu/>


                <div className="social-links">
                    <p>Онлайн игроков: {onlinePlayers.players} из {onlinePlayers.maxPlayers}</p>
                </div>

            </div>
        </header>
    )
}
