import {useEffect} from "react";

export function GameStart(){
    return (
        <section id="game_start" className="resume">
            <div className="container">
                <div className="section-title">
                    <p>Как играть?</p>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <h3 className="resume-title">Скачать лаунчер</h3>
                        <div className="resume-item pb-0">
                            <ul>
                                <li><a href="https://vk.com/tl_mc_launcher">Скачайте</a> и установите лаунчер</li>
                                <li>После запуска нажмите "Сетевая игра"</li>
                                <li>Снизу нажимаем кнопку "Добавить", пишите название <code>AltWorld</code> и адрес
                                    сервера <code>altworld.online</code></li>
                                <li>После подключения к серверу, вам будет предложено скачать текстур пак, жмём "Приступить"</li>
                            </ul>
                        </div>

                        <h3 className="resume-title">Регистрация</h3>
                        <div className="resume-item">
                            <p>После входа на сервер введите команду <br/><code>/register пароль пароль</code></p>
                            <p>При повторном входе на сервер необходимо будет авторизоваться <br/><code>/l пароль</code></p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>
                </div>

            </div>
        </section>
    )
}
