import { useForm } from 'react-hook-form';
import {useEffect, useState} from "react";
import {HelpMethod} from "../HelpMethod";
import {Link} from "react-router-dom";
export function Profile(){
    const [userData, setUserData] = useState({result: "auth_error"});
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        const formData = new FormData();
        document.getElementById('loginInfo').hidden = true;
        formData.append('login', data.login);
        formData.append('pass', data.pass);
        fetch('/login', {
            method: "POST",
            body: formData,
        }).then(response=>response.json())
            .then(result=>{
                if(result.result === 'success'){
                    HelpMethod.getUserData(setUserData);
                }else{
                    document.getElementById('loginInfo').hidden = false;
                }
            })
    };

    useEffect(() => {
        HelpMethod.getUserData(setUserData);
    }, [userData.result]);
    return (
        <section id="profile">
            <div className="container">

                <div className="section-title">
                    <h2>Личный кабинет</h2>
                </div>

                <div className="row mt-2" id="profile">
                    {userData.result==='auth_error' && <div className="col-sm-6 mx-auto">

                        <form onSubmit={handleSubmit(onSubmit)} className="php-email-form mt-4">
                            <div className="mb-3">
                                <input {...register('login', { required: true })} type="text" className="form-control" placeholder="Ник игрока"/>
                            </div>
                            <div className="mb-3">
                                <input  {...register('pass', { required: true })} type="password" className="form-control" placeholder="Пароль"/>
                                <p hidden id="loginInfo" style={{color: "red"}}>Неправильный логин или пароль</p>
                            </div>
                            <div className="mb-3">
                                <button className="form-control" type="submit">Войти</button>
                            </div>
                        </form>
                    </div>}
                    {userData.result==='success' &&
                        <div className="row">
                            <div className="col-sm-6">
                                <p><strong>Ник: </strong> {userData.username}</p>
                                <p><strong>Баланс: </strong> {userData.Balance}</p>
                                <p>
                                    <strong>Статус: </strong>
                                    {userData.isLogged!==0 && <span style={{color: "limegreen"}}>online</span>}
                                    {!userData.isLogged && <span style={{color: "darkred"}}>offline</span>}
                                </p>
                                <p><strong>Дата регистрации: </strong> {userData.renderDate}</p>
                                <p><strong>Наиграно часов: </strong> {Math.floor(userData.TotalPlayTime/3600000)}</p>
                                <p><strong>Роль: </strong> {userData.group.map(item=>'['+item+'] ')}</p>
                            </div>
                            {userData.group.includes("Команда") &&
                                <div className="col-sm-6">
                                    <Link className="btn btn-success" to="/addBlock">Добавить блок</Link>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}
