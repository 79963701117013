import {useEffect, useRef, useState} from "react";
import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {useForm} from "react-hook-form";

function Select(props){
    return (
        <option value={props.id}>{props.name}</option>
    )
}
function Image(props){
    return <div className="alert text-center w-50 mx-auto mt-2 border"  role="alert"><img src={props.src} width="90%"/></div>
}
export function AddBlock(){
    const [wikiItemCategory, setWikiItemCategory] = useState([]);
    const [wikiInstrumentForItem, setWikiInstrumentForItem] = useState([]);
    const [blockImage, setBlockImage] = useState();
    const editor = useRef();
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };
    useEffect(()=>{
        fetch("/getWikiItemCategory")
            .then(response=>response.json())
            .then(result=>{
                setWikiItemCategory(result.map((item,i)=><Select key={i} name={item.name} id={item.id}/>));
            })
    }, []);
    useEffect(()=>{
        fetch("/getWikiInstrumentForItem")
            .then(response=>response.json())
            .then(result=>{
                setWikiInstrumentForItem(result.map((item,i)=><Select key={i} name={item.name} id={item.id}/>));
            })
    }, [])
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        document.getElementById("wikiBlockBtnSubmit").innerHTML="<i class=\"fas fa-spinner fa-spin\"></i> загрузка"
        const formData = new FormData();
        if(data.wikiItemCategory==="") data.wikiItemCategory=1;
        if(data.wikiInstrumentCategory==="") data.wikiInstrumentCategory=1;
        formData.append("description", editor.current.getContents());
        formData.append("stackCount", data.stackCount);
        formData.append("luminosityCount", data.luminosityCount);
        formData.append("wikiItemName", data.wikiItemName);
        formData.append("wikiItemCategory", data.wikiItemCategory);
        formData.append("wikiInstrumentCategory", data.wikiInstrumentCategory);
        formData.append("wikiItemNameEng", data.wikiItemNameEng);
        formData.append("explosionResistance", data.explosionResistance);
        formData.append("command", data.command);
        formData.append("blockImage", data.blockImage[0]);
        fetch('/addBlock', {
            method: "POST",
            body: formData
        }).then(response=>response.json())
            .then(result=>{
                if(result.result === "success"){
                    window.location.href = "/wiki";
                }else{
                    alert('Ошибка');
                    //window.location.href = "/addBlock";
                }
            });



    }
    const changeImage = (e)=>{
        console.log(e.target.files[0]);
        const reader = new FileReader();
        reader.onload = function(){ setBlockImage(<Image src={reader.result}/>); }
        reader.readAsDataURL(e.target.files[0]);
    }
    return (
        <section className="section-show">
            <div className="container">
                <div className="section-title">
                    <h2>Управление сайтом - добавить блок</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-sm-3 border py-2">
                            <p className="mb-0">Складываемый</p>
                            <div className="mb-2">
                                <input onClick={()=>{document.getElementById("stackCount").hidden = false}} value="true" className="form-check-input me-1" type="radio" name="stack" id="flexRadioStack2"/>
                                <label className="form-check-label me-3" htmlFor="flexRadioStack2">
                                    Да
                                </label>
                                <input onClick={()=>{document.getElementById("stackCount").hidden = true}} value="false" className="form-check-input me-1" type="radio" name="stack" id="flexRadioStack1"/>
                                <label className="form-check-label me-3" htmlFor="flexRadioStack1">
                                    Нет
                                </label>
                            </div>
                            <input {...register('stackCount', )} hidden id="stackCount" className="form-control" type="number" placeholder="Кол-во в стаке" max="64"/>
                            <hr className="m-0"/>
                            <p className="mb-0">Светимость</p>
                            <div className="mb-2">
                                <input onClick={()=>{document.getElementById("luminosityCount").hidden = false}} value="true" className="form-check-input me-1" type="radio" name="luminosity" id="flexRadioLuminosity2"/>
                                <label className="form-check-label me-3" htmlFor="flexRadioLuminosity2">
                                    Да
                                </label>
                                <input onClick={()=>{document.getElementById("luminosityCount").hidden = true}} value="false" className="form-check-input me-1" type="radio" name="luminosity" id="flexRadioLuminosity1"/>
                                <label className="form-check-label me-3" htmlFor="flexRadioLuminosity1">
                                    Нет
                                </label>
                            </div>
                            <input {...register('luminosityCount', )} hidden id="luminosityCount" className="form-control" type="number" placeholder="Значение" max="15" min="0"/>
                        </div>
                        <div className="col-sm-3 border">
                            <label htmlFor="wikiItemName">Название</label>
                            <input  {...register('wikiItemName', {required: true})} type="text" placeholder="Название блока" className="form-control" id="wikiItemName"/>
                            <label htmlFor="wikiItemCategory">Категория</label>
                            <select className="form-select" {...register('wikiItemCategory')} id="wikiItemCategory">
                                {wikiItemCategory}
                            </select>
                            <label htmlFor="wikiInstrumentCategory">Инструмент</label>
                            <select className="form-select mb-2"  {...register('wikiInstrumentCategory')} id="wikiInstrumentCategory">
                                {wikiInstrumentForItem}
                            </select>
                        </div>
                        <div className="col-sm-3 border">
                            <label htmlFor="wikiItemNameEng">Название на англ.яз (ориг)</label>
                            <input  {...register('wikiItemNameEng', {required: true})} type="text" placeholder="Block name" className="form-control" id="wikiItemNameEng"/>
                            <label htmlFor="">Взрывоустойчивость</label>
                            <input {...register('explosionResistance', {required: true})}  type="number" className="form-control"/>
                            <label htmlFor="">Команда для получения</label>
                            <input {...register('command', {required: true})} type="text" className="form-control mb-2" placeholder="/give @p <minecraft:НАЗВАНИЕ>"/>
                        </div>
                        <div className="col-sm-3 border">
                            Изображение
                            <input {...register('blockImage', {required: true})} onChange={changeImage} type="file" className="form-control btn btn-success"/>
                            {blockImage}
                        </div>
                        <div className="col-sm-12 my-3">
                            <SunEditor getSunEditorInstance={getSunEditorInstance} height="40vh" />
                        </div>
                        <button id="wikiBlockBtnSubmit" type="submit" className="form-control btn btn-success btn-lg">Добавить</button>
                    </div>
                </form>
            </div>
        </section>
    )
}
