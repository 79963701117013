import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

function BlockInfo(props){
    return (
        <div className="row">
            <div className="col-sm-3 col-md-4 text-center">
                <img src={props.info.image}  width="60%" alt=""/>
                <p className="text-start my-3">
                    <strong>Тип: </strong>{props.info.category}<br/>
                    <strong>Инструмент: </strong>{props.info.instrument}<br/>
                    <strong>Складываемый: </strong>{props.info.stack_count}<br/>
                    <strong>Взрывоустойчивость: </strong> {Number.isInteger(+props.info.explosion_resistance)?props.info.explosion_resistance:props.info.explosion_resistance.toFixed(2)}<br/>
                    <strong>Светимость: </strong> {props.info.luminosity_count?props.info.luminosity_count:"Нет"}
                </p>
            </div>
            <div className="col-sm-5">
                <h2>{props.info.name}</h2>
                <p dangerouslySetInnerHTML={{__html: props.info.description}}/>
                <p><strong>Команда для получения</strong><br/>{props.info.command}</p>
            </div>
        </div>
    )
}

export function Block(){
    let { id } = useParams();
    const [blockInfo, setBlockInfo] = useState([]);
    useEffect(()=>{
        document.querySelector('[href="/wiki"]').classList.add('active');
        fetch(`/getBlock/${id}`)
            .then(response=>response.json())
            .then(result=>{
                setBlockInfo(<BlockInfo info={result} />)
            })
    }, []);
    return (
        <section id="block">
            <div className="container">
                <div className="section-title">
                    <h2>AltWorld - wiki</h2>
                </div>
                {blockInfo}
            </div>
        </section>
    )
}
